import { cn } from '@/utils';
import React from 'react';

type TitleProps = {
  children: React.ReactNode;
  className?: string;
};
export default function Title({ children, className }: TitleProps) {
  return (
    <h1
      className={cn(
        'text-xl w-[50%] md:w-full  md:text-5xl text-primary-900 font-bold',
        className,
      )}
    >
      {children}
    </h1>
  );
}
