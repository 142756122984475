'use client';

import { Ratings } from '@/components/rating/rating';
import Image from 'next/image';
import Title from './title';

const testimonials = [
  {
    name: 'Dany Monson',
    role: 'CEO & Founder',
    image: '/Images/hero.png',
    quote:
      '"It is very easy to communicate with Besoft and I love the way they value in my point of view. We have seen discovery call be overrated."',
  },
  {
    name: 'Abby Hollis',
    role: 'Lead at Unilink vlc',
    image: '/Images/hero.png',
    quote:
      '"I\'ve played around with several tools in the generative marketing copy space, and Besoft is by far the best I\'ve seen."',
  },
  {
    name: 'Omar Farook',
    role: 'Product Design',
    image: '/Images/hero.png',
    quote:
      '"Besoft helps produce templates for writing headlines, introductory emails copy, Facebook ads and so much more!"',
  },
];

export default function Testimonials() {
  return (
    <section className="py-5 lg:py-8 xl:py-15">
      <div className="grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-8">
        <Title> What says our happy clients</Title>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="bg-white px-8 py-10 rounded-lg">
            <div className="flex items-start gap-4">
              <Image
                src={testimonial.image}
                alt={testimonial.name}
                width={52}
                height={52}
                className="rounded-full"
              />
              <div className="grid gap-[6px]">
                <h3 className="text-lg font-semibold text-gray-800">
                  {testimonial.name}
                </h3>
                <p className="text-base text-gray-700">{testimonial.role}</p>
                <Ratings rating={5} />
              </div>
            </div>

            <p className="text-gray-700 text-sm mt-6">{testimonial.quote}</p>
          </div>
        ))}
      </div>
    </section>
  );
}
