'use client';

import { Button } from '@tajdid/tajdid-ui';
import Image from 'next/image';
import HeroImage from '../../../../public/Images/hero.png';
import HeroBackground from '../../../../public/Images/hero-bg.png';
import { Link } from '@/i18n/routing';

export default function Hero() {
  return (
    <section className="bg-white pt-5">
      <div className="container grid grid-cols-1 md:grid-cols-2 place-items-center gap-8 md:gap-0">
        <div>
          <h1 className="text-4xl md:text-5xl xl:text-[72px] xl:leading-[130%]  w-full font-bold text-primary-900 capitalize">
            Achieve your <br /> goals with our <br />
            <span className="text-primary-500">precision platform!</span>
          </h1>
          <p className="text-gray-600 text-sm md:text-lg font-normal w-[90%] md:w-[75%] mt-4 md:mt-4]">
            Empowering You with Cutting-Edge Tools for Precise Goal Achievement.
          </p>
          <Link href="/courses">
            <Button
              size="large"
              className="bg-primary-500 hover:bg-primary-700 text-white px-6 mt-[52px]"
            >
              View the course
            </Button>
          </Link>
        </div>

        <div className="relative">
          <Image
            src={HeroImage}
            alt="Hero Top"
            priority
            placeholder="blur"
            className="relative z-10 max-w-xs md:max-w-md"
          />
          <Image
            src="/Images/hero-cap.png"
            alt="Hero Cap"
            width={85}
            height={85}
            className="absolute top-2 left-10 md:left-16 z-10"
          />
          <Image
            src={HeroBackground}
            alt="Hero Background"
            priority
            placeholder="blur"
            className="absolute top-0 left-0 z-0 w-full max-w-xs md:max-w-md"
          />
          <div className="flex justify-between items-center gap-4 bg-white p-3 md:px-[56px]  md:py-[15px] rounded-md border border-[#FEF6E9] absolute bottom-[0px] left-0 md:bottom-[30px] lg:bottom-[72px]  md:-left-[200px] z-50">
            <Image
              src="/Images/price.png"
              alt="price"
              priority
              width={42}
              height={42}
            />
            <p className="text-sm md:text-base font-semibold">Free courses</p>
          </div>
          <div className="bg-white bg-opacity-80 text-center border border-primary-100 p-3 md:px-[38px] md:py-[15px] absolute right-0 md:-right-[20px] lg:-right-[23px] xl:-right-[93px] bottom-[120px]  lg:bottom-[219px] z-50 rounded-md">
            <p className="text-success-500  text-xl md:text-2xl lg:text-4xl font-bold">
              10M+
            </p>
            <p className="text-gray-500 text-sm md:text-base font-medium">
              Total Courses
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
